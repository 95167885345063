import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from '@material-ui/pickers';
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import { formatDate, isValidDate } from 'util/Utils';

const DatePicker = ({
  info,
  onUpdate,
  fieldsValidCallback,
  withErrors,
  showAllErrors,
  variant,
  formatted,
  inputVariant,
  disablePast
}) => {
  const [open, setOpen] = useState(false);
  const [selectedDate, setSelectedDate] = useState(
    info ? new Date(info) : new Date(Date.now())
  );
  const handleDateChange = (date, isFinish) => {
    console.log('handleDateChange', date, isFinish);
    setSelectedDate(date);
  };

  const [isValid, setIsValid] = useState(true);
  const handleError = (error) => {
    console.log('ON Error-', error, '-', selectedDate);
    setIsValid(!error ? true : false);
  };

  useEffect(() => {
    console.log(
      'UseFX-',
      selectedDate,
      '-',
      isValid,
      '-',
      isValidDate(selectedDate)
    );
    fieldsValidCallback(isValid);
    if (isValidDate(selectedDate) && isValid) {
      console.log('calling onUpdate-', selectedDate, '-', isValid);
      onUpdate(formatted ? formatDate(selectedDate) : selectedDate);
    } else {
      onUpdate(null);
    }
  }, [selectedDate, isValid]);

  return (
    // <div className="d-flex align-items-center">
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <KeyboardDatePicker
        // disableToolbar
        // open={open}
        variant={variant}
        inputVariant={inputVariant}
        format="MM/dd/yyyy"
        margin="normal"
        id="date-picker-inline"
        label="(mm/dd/yyyy)"
        disablePast={disablePast}
        value={selectedDate}
        onOpen={() => setOpen(true)}
        onAccept={() => setOpen(true)}
        onChange={handleDateChange}
        onError={handleError}
        KeyboardButtonProps={{
          'aria-label': 'change date',
          color: 'primary',
        }}
        />
    </MuiPickersUtilsProvider>
    // </div>
  );
};

DatePicker.defaultProps = {
  info: Date.now(),
  withErrors: true,
  onUpdate: () => { },
  fieldsValidCallback: () => { },
  variant: 'inline',
  formatted: false,
  inputVariant: 'standard',
  disablePast: false
};

DatePicker.propTypes = {
  info: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string,
    PropTypes.number
  ]),
  onUpdate: PropTypes.func.isRequired,
  showAllErrors: PropTypes.bool,
  withErrors: PropTypes.bool,
  fieldsValidCallback: PropTypes.func,
  variant: PropTypes.oneOf(['inline', 'dialog', 'static']),
  inputVariant: PropTypes.oneOf(['filled', 'outlined', 'standard']),
  formatted: PropTypes.bool,
  disablePast: PropTypes.bool
};

export default DatePicker;
