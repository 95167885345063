import {
  SET_SOCKET,
} from '../actions';

const initialState = {
  socket:null
};

export default function socketConnection(state = initialState, action) {
  switch (action.type) {
      case SET_SOCKET:
          return {
              socket: action.payload
          };
      default:
          return state;
  }
}