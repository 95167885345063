import { HttpMethods, HttpService } from './index';


export default class DashService {
  static checkUserSession(token) {
    return HttpService.request(HttpMethods.GET, `user/session?role=SHIPPER`, {}, {}, token).then(
      (res) => res
    );
  }
}
