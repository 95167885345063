import AccountType from 'model/enum/AccountType';
import { HttpMethods, HttpService } from './index';

export default class ProfileService {
  static createUser(accountType, accountData) {
    let url = 'user/signup';
    if (accountType && accountType === AccountType.FORWARDER) {
      url = 'register/newuser/forwarder';
    }
    //const payload = ProfileService.createUser(userData);

    return HttpService.request(HttpMethods.POST, url, accountData)
      .then((res) => {
        console.log('createUser SRVICE resp', res);
        return res;
      })
      .catch((err) => console.error(err));
  }

  static isExistingUser(data) {
    const url = 'user/exists';

    return HttpService.request(HttpMethods.POST, url, data)
      .then((res) => {
        console.log('SRVICE resp', res);
        return res && res.returnCode === 0 ? true : false;
      })
      .catch((err) => console.error(err));
  }

  static validateReferralCode(data) {
    const url = 'referral/validate';

    return HttpService.request(HttpMethods.POST, url, data)
      .then((res) => {
        console.log('SRVICE resp', res);
        return res;
      })
      .catch((err) => console.error(err));
  }

  static getUserInfo(uid, token) {
    return HttpService.request(
      HttpMethods.GET,
      `user/get-shipper-by-id?userId=${uid}`,
      {},
      {},
      token
    ).then((res) => res);
  }

  static updateUserInfo(uid, data, token) {
    return HttpService.request(
      HttpMethods.POST,
      `user/${uid}/update`,
      data,
      {},
      token
    )
      .then((res) => res)
      .catch((err) => console.error(err));
  }

  static updateUserBillingInfo(uid, data, token) {
    return HttpService.request(
      HttpMethods.POST,
      `user/${uid}/updateBilling`,
      data,
      {},
      token
    )
      .then((res) => res)
      .catch((err) => console.error(err));
  }

  static updateUserCompanyInfo(uid, data, token) {
    return HttpService.request(
      HttpMethods.POST,
      `user/${uid}/companyUpdate`,
      data,
      {},
      token
    )
      .then((res) => res)
      .catch((err) => console.error(err));
  }

  static updateUserAvatar(uid, formData, token) {
    return HttpService.postFormData(
      `user/${uid}/updateAvatar`,
      formData,
      {},
      token
    )
      .then((res) => res)
      .catch((err) => console.error(err));
  }

  static deleteUserAvatar(uid, token) {
    return HttpService.request(
      HttpMethods.DELETE,
      `user/${uid}/deleteAvatar`,
      {},
      {},
      token
    )
      .then((res) => res)
      .catch((err) => console.error(err));
  }

  static updateCompanyLogo(uid, cmpyId, formData, token) {
    return HttpService.postFormData(
      `cmpy/${uid}/updateLogo/${cmpyId}`,
      formData,
      {},
      token
    )
      .then((res) => res)
      .catch((err) => console.error(err));
  }

  static deleteCompanyLogo(uid, cmpyId, token) {
    return HttpService.request(
      HttpMethods.DELETE,
      `cmpy/${uid}/deleteLogo/${cmpyId}`,
      {},
      {},
      token
    )
      .then((res) => res)
      .catch((err) => console.error(err));
  }

  static updateUserShippingInfo(uid, data, token) {
    return HttpService.request(
      HttpMethods.POST,
      `user/${uid}/shippingUpdate`,
      data,
      {},
      token
    )
      .then((res) => res)
      .catch((err) => console.error(err));
  }

  static changePassword(uid, data, token) {
    return HttpService.request(
      HttpMethods.POST,
      `user/${uid}/changePassword`,
      data,
      {},
      token
    )
      .then((res) => res)
      .catch((err) => console.error(err));
  }
}
