import {
REFERRALCODE,
} from '../actions';

const initialState = {
  referral_code: null,

};

export default function referralCodeReducer(state = initialState, action) {
  switch (action.type) {
    case REFERRALCODE:
      return {
        ...state,
        referral_code: action.payload
      };

    default:
      return state;
  }
}
