import {
    SET_CHAT_ROOM,
    SET_CHAT_DATA,
    UPDATE_CHAT_DATA,
    CLEAR_CHAT_DATA,
    EXPIRE_OFFER_FROM_CHAT,
    ACCEPT_OFFER_FROM_CHAT,
    UPDATE_UNREAD_CHATS_COUNT

} from '../actions';
import { UserType } from '../model/enum/AccountType';

const initialState = {
    room_id: null,
    chat_data: null,
    unreadChats: 0
};

export default function chatRoom(state = initialState, action) {
    switch (action.type) {
        case SET_CHAT_ROOM:
            return {
                ...state,
                room_id: action.payload
            };
        case SET_CHAT_DATA:
            return {
                ...state,
                chat_data: action.payload
            };

        case UPDATE_UNREAD_CHATS_COUNT:
            return {
                ...state,
                unreadChats: action.payload
            };

        case UPDATE_CHAT_DATA:

        const message = {
            ...action.payload,
            senderName: action?.payload?.userType === UserType.SHIPPER ? action.payload.userName : action.payload.companyName,
            senderAvatar: action?.payload?.userType === UserType.SHIPPER ? action.payload.userAvatar : action.payload.companyLogo,
        }
            const conversation = state.chat_data.conversation
            const lastElement = conversation[conversation.length - 1];
            if (lastElement.created === 'Today') {
                // Push new message to the data array of the last element
                lastElement.data.push(message);
            } else {
                // Push a new element to the conversation array
                conversation.push({
                    created: 'Today',
                    data: [message]
                });
            }

            return state


        // const lastGroup = conversation[conversation.length - 1]
        // if (lastGroup && lastGroup.created === "Today") {
        //     return {
        //         ...state,
        //         chat_data: {
        //             ...state.chat_data,
        //             conversation: [...state.chat_data.conversation, action.payload]
        //         }
        //     };
        // } else {
        //     const data = {
        //         created: "Today",
        //         data: action.payload
        //     }
        //     return {
        //         ...state,
        //         chat_data: {
        //             ...state.chat_data,
        //             conversation: [...state.chat_data.conversation, data]
        //         }
        //     };
        // }


        case EXPIRE_OFFER_FROM_CHAT:
            const updatedConversationExpire = state.chat_data.conversation.map((item, i) => {
                if (i === action.payload.index) {
                    const updatedContent = { ...item.content, acceptable: false, status: "Expired" };
                    return {
                        ...item,
                        content: updatedContent,
                    };
                }
                return item;
            });


            return {
                ...state,
                chat_data: {
                    ...state.chat_data,
                    conversation: updatedConversationExpire,
                },
            };


        case ACCEPT_OFFER_FROM_CHAT:
            const updatedConversationAccept = state.chat_data.conversation.map((item, i) => {
                if (i === action.payload.index) {
                    const updatedContent = { ...item.content, acceptable: false, status: "Accepted" };
                    return {
                        ...item,
                        content: updatedContent,
                    };
                }
                return item;
            });


            return {
                ...state,
                chat_data: {
                    ...state.chat_data,
                    conversation: updatedConversationAccept,
                },
            };





        case CLEAR_CHAT_DATA:
            return initialState;

        default:
            return state;
    }
}