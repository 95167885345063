import { httpService, HttpMethods } from './global';

export default class ShipmentRequestService {
  static createShipmentRequest(data, token) {
    const url = 'shipment-request/s/create';

    return httpService(HttpMethods.POST, url, data, {
      token,
      type: 'quotes',
      method: 'create',
      platform: 'Shipper'
    })
      .then((res) => {
        return res;
      })
      .catch((err) => console.log(err));
  }

  static fetchShipmentRequest(data, token) {
    const url = `shipment-request/s/fetch`;

    return httpService(HttpMethods.POST, url, data, {
      token,
      type: 'quotes',
      method: 'read',
      platform: 'Shipper'
    })
      .then((res) => {
        return res;
      })
      .catch((err) => console.log(err));
  }

  static fetchShipmentRequestProposals(data, token) {
    const url = `shipment-request/s/fetch/proposals`;

    return httpService(HttpMethods.POST, url, data, {
      token,
      type: 'quotes',
      method: 'read',
      platform: 'Shipper'
    })
      .then((res) => {
        return res;
      })
      .catch((err) => console.log(err));
  }

  static changeRshipmentRequestStatus(data, token) {
    const url = `shipment-request/s/update/status`;

    return httpService(HttpMethods.POST, url, data, {
      token,
      type: 'quotes',
      method: 'edit',
      platform: 'Shipper'
    })
      .then((res) => {
        return res;
      })
      .catch((err) => console.log(err));
  }
}
