import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { ShipperService } from 'service'
import { notify } from 'sites/shipper/components/Toast/toast';
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import LoaderComponent from '../../components/LoaderComponent';
const AcceptInvitaion = () => {


    const dispatch = useDispatch()
    const { forwarderProfileId, shipperProfileId } = useParams()
    const [loading, setLoading] = useState(true)
    const history = useHistory()

    useEffect(() => {
        const data_to_send = {
            shipperProfileId: shipperProfileId,
            forwarderProfileId: forwarderProfileId
        }
        ShipperService.acceptMyShipperInvitaion(data_to_send).then(res => {
            if (res.error) {
                // window.alert(res.error)
                notify(dispatch, "error", res.error)
                history.replace('/login')
                setLoading(false)
            } else {
                // window.alert(res.message)
                notify(dispatch, "success", res.message)
                history.replace('/login')
                setLoading(false)
            }
        })
    }, [forwarderProfileId, shipperProfileId])

    return (
        <div>
            {loading &&
                <LoaderComponent loading={loading}/>
            }
        </div>
    )
}

export default AcceptInvitaion