import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Checkbox,
  Button,
  OutlinedInput,
  InputAdornment,
  IconButton,
  Divider
} from '@material-ui/core';
import ShipperTeam from 'service/ShipperTeam';
import Style from './TeamMemberRegistration.module.css';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { style } from '@mui/system';
import DatePicker from 'components/DatePicker';
import WrapperContainer from '../../Login/WrapperContainer/WrapperContainer';
import { notify } from 'sites/shipper/components/Toast/toast';
import { useDispatch } from 'react-redux';
import ConstantValuesService from 'service/ConstantValuesService';
import StyledInput from 'components/StyledInput';
import PhoneNumberInput from 'components/PhoneNumberInput';
import StyledSelect from 'components/StyledSelect';
import { isValidPassword, DOBErrorMsg, isValidDateOfBirth } from 'util/Utils';

const dumD = [
  { name: 'data', value: 'value' },
  { name: 'data1', value: 'value1' },
  { name: 'data2', value: 'value2' }
];

const TeamMemberRegistartion = () => {
  const dispatch = useDispatch();

  const history = useHistory();
  const { token } = useParams();

  const [expiredToken, setExpiredToken] = useState(false);
  const [DOBError, setDOBError] = useState('');

  const [showPassword, setShowPassword] = React.useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const [data, setData] = useState({
    token: token,
    firstName: '',
    lastName: '',
    phoneNumber: '',
    phoneNumberCode: '',
    password: '',
    dateOfBirth: '',
    gender: '',
    jobTitle: ''
    // suite: '',
    // street: '',
    // city: '',
    // state: '',
    // country: '',
    // postalCode: ''
  });

  const handleValue = (e) => {
    setError('');
    const { name, value } = e.target;
    if (name === 'dateOfBirth') {
      setDOBError('');
      if (!isValidDateOfBirth(value)) {
        setDOBError(DOBErrorMsg);
      }
    }
    setData({
      ...data,
      [name]: value
    });
  };

  useEffect(() => {
    ShipperTeam.isTokenExpired(token).then((result) => {
      if (!result.success) {
        setExpiredToken(true);
      } else {
        setExpiredToken(false);
      }
    });
  }, [token]);

  const [error, setError] = useState('');
  const [passwordStrengthError, setPasswordStrengthError] = useState('');

  const checkPasswordStrength = () => {
    setError('');
    if (isValidPassword(data.password)) {
      setPasswordStrengthError('');
    } else {
      setPasswordStrengthError(
        'Password must be at least 8 characters long, contain at least one digit, one lowercase and one uppercase letter'
      );
    }
    return;
  };

  const handleSubmit = () => {
    setError('');
    // console.log(data);
    if (
      data.dateOfBirth === '' ||
      data.firstName === '' ||
      data.lastName === '' ||
      data.password === '' ||
      data.jobTitle === '' ||
      data.gender === '' ||
      // data.personal_city === "" ||
      // data.personal_country === "" ||
      // data.personal_postal_code === "" ||
      // data.personal_state === "" ||
      // data.personal_street === "" ||
      // data.personal_suite === "" ||
      data.phoneNumber === '' ||
      data.phoneNumberCode === ''
    ) {
      setError('All Fields are required');
      return;
    }
    ShipperTeam.registerTeam(data).then((response) => {
      if (response.error) {
        setError(response.error);
      } else {
        // window.alert(response.message)
        notify(dispatch, 'success', response.message);
        history.push('/login');
      }
    });
  };

  // console.log(data)
  // const [phoneNumberOption, setPhoneNumberOption] = useState([]);

  // useEffect(() => {
  //   ConstantValuesService.fetchCountryList().then((res) => {
  //     if (res.error) {
  //       console.log(res.error);
  //     } else {
  //       const array = [];
  //       res.map((item, index) => {
  //         array.push({
  //           value: `+${item.phone}`,
  //           // label: item.value, //country short name
  //           label: `+${item.phone}` // country phone code
  //           // label:item.label, // country full name
  //         });
  //       });
  //       setPhoneNumberOption(array);
  //     }
  //   });
  // }, []);

  return (
    <>
      {/* <h3>Team Member Registration form</h3>
      <input  placeholder="First Name" type="text" name="firstName" value={data.firstName} onChange={handleValue}/><br/>
      <input  placeholder="Last Name" type="text" name="lastName" value={data.lastName} onChange={handleValue}/><br/>
      <input  placeholder="Phone Number" type="number" name="phoneNumber" value={data.phoneNumber} onChange={handleValue}/><br/>
      Date of Birth<input  placeholder="Date Of Birth" type="date" name="dateOfBirth" value={data.dateOfBirth} onChange={handleValue}/><br/>
      <h3>Personal Address</h3>
      <input  placeholder="Suite" type="text" name="personal_suite" value={data.personal_suite} onChange={handleValue}/><br/>
      <input  placeholder="Street" type="text" name="personal_street" value={data.personal_street} onChange={handleValue}/><br/>
      <input  placeholder="City" type="text" name="personal_city" value={data.personal_city} onChange={handleValue}/><br/>
      <input  placeholder="State" type="text" name="personal_state" value={data.personal_state} onChange={handleValue}/><br/>
      <input  placeholder="Country" type="text" name="personal_country" value={data.personal_country} onChange={handleValue}/><br/>
      <input  placeholder="Postal Code" type="text" name="personal_postal_code" value={data.personal_postal_code} onChange={handleValue}/><br/>
      <input  placeholder="Password" type="password" name="password" value={data.password} onChange={handleValue}/><br/>
      <button onClick={handleSubmit}>Register</button> */}

      {/* <div className='d-flex justify-content-center'>
              <div style={{ display: 'flex', flexDirection: 'column', gap: '1.5rem' }}>
                <div className={Style.left_header}>Team Member <br /> Register Form</div>

                <div className={Style.trust_text}>
                  Experience why over 2 million customers choose
                  Cuboid POS
                </div>
                <div className='d-flex flex-column'>
                  <span className={Style.trust_text}>Do you prefer talking to a representative?</span>
                  <span className={Style.call_text}>Call us at 800 1301 448</span>
                </div>
              </div>
            </div> */}

      <WrapperContainer showText={false}>
        {expiredToken ? (
          <>
            <div className={Style.form_container}>
              <div className="d-flex flex-column align-items-center c-gap-1">
                <span className={Style.form_legend}>
                  Invitation link is invalid or has expired. Ask your company
                  admin to resend invitation.
                </span>
                {/* <a href='/login'>Click Here to login</a></div> */}
                <img
                  src="assets\authentication\invalid_token.svg"
                  alt="Invalid Invitation"
                  style={{ maxWidth: '320px' }}
                />
                <div className={Style.form_actions_container}>
                  <Button
                    className={`${Style.signin_btn} btn-primary`}
                    href="/login">
                    Login
                  </Button>
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className={Style.form_container}>
              <div className={Style.form}>
                <span className={Style.form_legend}>
                  Team Member Register Form
                </span>

                <div className={Style.grid_container}>
                  <StyledInput
                    margin="0"
                    label="First Name"
                    id="first-name"
                    name="firstName"
                    value={data.firstName}
                    onChange={handleValue}
                  />
                  <StyledInput
                    margin="0"
                    label="Last Name"
                    id="last-name"
                    name="lastName"
                    value={data.lastName}
                    onChange={handleValue}
                  />

                  <PhoneNumberInput
                    number={data.phoneNumber}
                    country={data.phoneNumberCode}
                    handleNumber={(val) =>
                      setData({ ...data, phoneNumber: val })
                    }
                    handleCountry={(val) =>
                      setData({ ...data, phoneNumberCode: val })
                    }
                  />
                  <StyledInput
                    margin="0"
                    label="Job Title"
                    id="job-title"
                    name="jobTitle"
                    value={data.jobTitle}
                    onChange={handleValue}
                  />

                  {/* <InputField label='Phone Number' id='phone-number' name='phoneNumber' value={data.phoneNumber} onChange={handleValue}

                    startAdornment={
                      <InputAdornment position='start'>
                        <Select
                          className={`${Style.phone_code} ${Style.input}`}
                          value={data.phoneNumberCode}
                          name='phoneNumberCode'
                          onChange={handleValue}
                        >
                          {phoneNumberOption.map((item, index) => (
                            <MenuItem key={index} value={item.value}>{item.label}</MenuItem>
                          ))}
                        </Select>
                      </InputAdornment>
                    }
                  /> */}

                  <StyledInput
                    InputLabelProps={{
                      shrink: true
                    }}
                    InputProps={{
                      placeholder: data.dateOfBirth
                        ? undefined
                        : 'Date of Birth'
                    }}
                    margin="0"
                    type="date"
                    label="Date of Birth"
                    id="d-o-b"
                    name="dateOfBirth"
                    value={data.dateOfBirth}
                    onChange={handleValue}
                    error={DOBError}
                  />

                  <StyledSelect
                    label="Select Gender*"
                    name="gender"
                    margin="0"
                    value={data.gender}
                    onChange={(e) =>
                      setData({ ...data, gender: e.target.value })
                    }
                    size="small"
                    options={[
                      { value: 'Male', label: 'Male' },
                      { value: 'Female', label: 'Female' },
                      { value: 'Other', label: 'Other' }
                    ]}
                  />

                  <FormControl variant="outlined" size="small">
                    <InputLabel htmlFor="outlined-adornment-password">
                      Password
                    </InputLabel>
                    <OutlinedInput
                      name="password"
                      value={data.password}
                      onChange={handleValue}
                      autoComplete="password"
                      onBlur={checkPasswordStrength}
                      // className={`${Style.input}`}
                      id="outlined-adornment-password"
                      type={showPassword ? 'text' : 'password'}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end">
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      }
                      label="Password"
                    />

                    {passwordStrengthError && (
                      <p className="text-danger font-size-xs my-2">
                        {passwordStrengthError}
                      </p>
                    )}
                  </FormControl>
                </div>

                {/* <span className={Style.sub_legend}>Personal Address</span>

                <div className={Style.grid_container}>
                  <InputField label='Suite' id='personal-suite' name="personal_suite" value={data.personal_suite} onChange={handleValue} />
                  <InputField id='personal-street' placeholder='Street' label='Street' name="personal_street" value={data.personal_street} onChange={handleValue} />
                  <InputField id='personal-city' placeholder='City' label='City' name="personal_city" value={data.personal_city} onChange={handleValue} />
                  <InputField id='personal-state' placeholder='State' label='State' name="personal_state" value={data.personal_state} onChange={handleValue} />
                  <InputField id='personal-country' placeholder='Country' label='Country' name="personal_country" value={data.personal_country} onChange={handleValue} />
                  <InputField label='Postal Code' id='personal_postal_code' name="personal_postal_code" value={data.personal_postal_code} onChange={handleValue} />
                </div> */}
                {error && (
                  <p className="text-danger font-size-xs my-2">{error}</p>
                )}
                <Button
                  className={`btn-primary`}
                  onClick={handleSubmit}
                  disabled={
                    data.dateOfBirth === '' ||
                    data.firstName === '' ||
                    data.lastName === '' ||
                    data.password === '' ||
                    data.gender === '' ||
                    data.jobTitle === '' ||
                    data.phoneNumber === '' ||
                    data.phoneNumberCode === '' ||
                    passwordStrengthError ||
                    DOBError
                  }>
                  Register
                </Button>
              </div>

              <Divider />

              <div className={Style.caption}>
                By registering, you agree to the processing of your personal
                data by Kargoplex as described in the privacy policy and terms
                of service.
              </div>
            </div>
          </>
        )}
      </WrapperContainer>
    </>
  );
};

const InputField = ({
  name = '',
  value = '',
  onChange,
  label = '',
  placeholder = '',
  id = '',
  type = 'text',
  startAdornment,
  classses,
  ...rest
}) => (
  <FormControl variant="outlined" size="small">
    {type === 'date' ? (
      <InputLabel
        shrink={type === 'date' || name === 'phoneNumber'}
        className={`${type === 'date' && Style.date_field_label}`}
        htmlFor={id}>
        {label}
      </InputLabel>
    ) : (
      <InputLabel htmlFor={id}>{label}</InputLabel>
    )}
    <OutlinedInput
      className={`${Style.input} ${classses}`}
      id={id}
      type={type}
      label={label}
      name={name}
      value={value}
      onChange={onChange}
      placeholder={placeholder}
      startAdornment={startAdornment}
    />
  </FormControl>
);

const SelectField = ({
  name = '',
  value = '',
  onChange,
  label = '',
  placeholder = '',
  // id = '',
  type = 'text',
  options,
  ...rest
}) => (
  <FormControl variant="outlined" size="small">
    <InputLabel id="demo-simple-select-label">{label}</InputLabel>
    <Select
      className={Style.input}
      labelId="demo-simple-select-label"
      value={value}
      onChange={onChange}
      name={name}
      label={label}>
      {options?.map((option, idx) => (
        <MenuItem key={idx} value={option.value}>
          {option.name}
        </MenuItem>
      ))}
    </Select>
  </FormControl>
);
export default TeamMemberRegistartion;
