import { getBaseUrl } from 'config/config';
import { HttpMethods, HttpService } from './index';
import { httpService } from './global';

export default class PreferencesService {
  static async fetchPreferencesData(id, token) {
    const url = `shipper/fetch-preferences/${id}`;

      return httpService(HttpMethods.GET, url, null, {
        token,
        type: 'preferences_settings',
        method: 'read',
        platform: 'Shipper'
      })
        .then((res) => {
          return res;
        })
        .catch((err) => console.log(err));
  
  }

  static async managePreferences(data, token) {
    const url = `shipper/manage-preferences`;

      return httpService(HttpMethods.PUT, url, data, {
        token,
        type: 'preferences_settings',
        method: 'edit',
        platform: 'Shipper'
      })
        .then((res) => {
          return res;
        })
        .catch((err) => console.log(err));
  
  }

  
}
