import { combineReducers } from 'redux';
import dashboard from './dashboard';
import drawer from './drawer';
import messages from './messages';
import serviceworker from './serviceworker';
import signup from './signup';
import session from './session';
import user from './user';
import billing from './billing';
import ThemeOptions from './ThemeOptions';
import shippmentBooking from './shippmentBooking'
import chatRoom from './chat'
import socketConnection from './socket'
import filterDataRedux from './filterData'

import referralCodeReducer from "./referral"

import loadingReducer from './loader'

const rootReducer = combineReducers({
  dashboard,
  drawer,
  messages,
  serviceworker,
  signup,
  session,
  user,
  billing,
  ThemeOptions,
  shippmentBooking,
  chatRoom,
  socketConnection,
  filterDataRedux,
  referralCodeReducer,
  loadingReducer
});

export default rootReducer;
