import { getBaseUrl } from 'config/config';
import { HttpMethods, httpService } from './global';

export default class CollaboratorService {
  static async fetchCollaborators(id, token, page, query) {
    const url = `${getBaseUrl()}tool/fetch-collaborator?id=${id}&query=${query}&page=${page}`;
    const result = await fetch(url, {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + token
      }
    });
    const response = await result.json();
    return response;
  }


  static async fetchCollaboratorById(id, token) {
    const url = `${getBaseUrl()}tool/fetch-collaborator-details?id=${id}`;
    const result = await fetch(url, {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + token
      }
    });
    const response = await result.json();
    return response;
  }




  static createCollaborator(data, token) {
    const url = 'tool/create-collaborator';
    return httpService(HttpMethods.POST, url, data, {
      token,
      type: 'collaborators',
      method: "create",
      platform: "Shipper"
    })
      .then((res) => {
        console.log('createUser SRVICE resp', res);
        return res;
      })
      .catch((err) => console.error(err));
  }



  static checkCollaboratorAllreadyExist(data, token) {
    const url = 'tool/check-collaborator-email';
    return httpService(HttpMethods.POST, url, data, {
      token,
      type: 'collaborators',
      method: "create",
      platform: "Shipper"
    })
      .then((res) => {
        console.log('createUser SRVICE resp', res);
        return res;
      })
      .catch((err) => console.error(err));
  }


  static deleteCollaborator(id, token) {
    const url = `tool/delete-collaborator?id=${id}`;
    return httpService(HttpMethods.DELETE, url, {}, {
      token,
      type: 'collaborators',
      method: "delete",
      platform: "Shipper"
    })
      .then((res) => {
        return res;
      })
      .catch((err) => console.log(err));
  }

  static updateCollaborator(id, data, token) {
    const url = `tool/update-collaborator?id=${id}`;
    return httpService(HttpMethods.PUT, url, data, {
      token,
      type: 'collaborators',
      method: "edit",
      platform: "Shipper"
    })
      .then((res) => {
        console.log('createUser SRVICE resp', res);
        return res;
      })
      .catch((err) => console.error(err));
  }



  static isPasswordCreated(id) {
    const url = `tool/check-collaborator-password-created?id=${id}`;
    return httpService(HttpMethods.GET, url, null, null)
      .then((res) => {
        console.log('createUser SRVICE resp', res);
        return res;
      })
      .catch((err) => console.error(err));
  }


  static createCollaboratorPassword(data) {
    const url = `tool/create-collaborator-password`;
    return httpService(HttpMethods.POST, url, data, null)
      .then((res) => {
        console.log('createUser SRVICE resp', res);
        return res;
      })
      .catch((err) => console.error(err));
  }

  static fetchCollaboratorsShipments(data, token) {
    const url = `tool/fetch-collaborator-shipments`;
    return httpService(HttpMethods.POST, url, data, token)
      .then((res) => {
        console.log('createUser SRVICE resp', res);
        return res;
      })
      .catch((err) => console.error(err));
  }

  static fetchAnalyticsCollaborator(id) {
    const url = `tool/get-collaborator-analytics?id=${id}`;
    return httpService(HttpMethods.GET, url, null, null)
      .then((res) => {
        console.log('createUser SRVICE resp', res);
        return res;
      })
      .catch((err) => console.error(err));
  }


  static checkIsValidCollaborator(collaborator_id, shipment_id) {
    const url = `tool/check-valid-collaborator?collaborator_id=${collaborator_id}&shipment_id=${shipment_id}`;
    return httpService(HttpMethods.GET, url, null, null)
      .then((res) => {
        console.log('createUser SRVICE resp', res);
        return res;
      })
      .catch((err) => console.error(err));
  }


  static switchCompany(data, token) {
    const url = `tool/switch-company`;
    return httpService(HttpMethods.POST, url, data, token)
      .then((res) => {
        console.log('createUser SRVICE resp', res);
        return res;
      })
      .catch((err) => console.error(err));
  }
}
