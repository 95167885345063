import { setReferralCode } from 'actions';
import { ShipperReferralCodeVerification } from 'actions/signup';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { useParams } from 'react-router-dom';
import { ShipperService } from 'service';
import { useHistory } from 'react-router-dom';
import ReferralError from '../../RegisterReferral/ReferralError';
import LoaderComponent from 'sites/shipper/components/LoaderComponent';
const Referral = () => {
  const { referralCode } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();

  const [error, setError] = useState({ msg: '' });
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    ShipperService.verifyShipperReferralCode(referralCode).then((result) => {
      if (result?.message) {
        dispatch(setReferralCode(referralCode));

        history.push('/register');
      } else {
        console.log(result?.error);
        setLoading(false);

        setError({
          msg: result?.error
        });
      }
    });
  }, []);

  return (
    <div>
      {loading ? <LoaderComponent /> : <ReferralError content={error} />}
    </div>
  );
};

export default Referral;
