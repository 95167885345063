import { HttpMethods, HttpService } from './index';
import { getBaseUrl } from '../config/config';
import { httpService } from './global';


export default class ShipperTeam {
  static async inviteTeam(data,token) {
    const url = 'shipper/team/invite-team';

     return httpService(HttpMethods.POST, url, data, {
       token,
       type: 'company_team_settings',
       method: 'create',
       platform: 'Shipper'
     })
       .then((res) => {
         return res;
       })
       .catch((err) => console.log(err));
   
  }

  static async isTokenExpired(token) {
    const url = `shipper/team/is-token-expired?token=${token}`;
    const result = await fetch(getBaseUrl() + url);
    const response = await result.json();
    return response;
  }

  static async registerTeam(data) {
    const url = 'shipper/team/register-team';
    const result = await fetch(getBaseUrl() + url, {
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json'
      },
      method: 'POSt'
    });
    const response = await result.json();
    return response;
  }

  static async fetch_team(shipper_id,token) {
    const url = `shipper/team/fetch-team?shipper_id=${shipper_id}`;

     return httpService(HttpMethods.GET, url, null, {
       token,
       type: 'company_team_settings',
       method: 'read',
       platform: 'Shipper'
     })
       .then((res) => {
         return res;
       })
       .catch((err) => console.log(err));
   
   
  }

  static async update_team_member_status(data,token) {
    const url = 'shipper/team/update-team-member-status';

     return httpService(HttpMethods.POST, url, data, {
       token,
       type: 'company_team_settings',
       method: 'edit',
       platform: 'Shipper'
     })
       .then((res) => {
         return res;
       })
       .catch((err) => console.log(err));
   
  }

  static async get_team_member_by_id(id,token) {
    const url = `shipper/team/get-team-member-by-id?id=${id}`;

     return httpService(HttpMethods.GET, url, null, {
       token,
       type: 'company_team_settings',
       method: 'read',
       platform: 'Shipper'
     })
       .then((res) => {
         return res;
       })
       .catch((err) => console.log(err));
   
  }
  static async updatePermission(id, type, accountId, cmpyId) {
    const url = `shipper/team/update-permission?id=${id}&type=${type}&accountId=${accountId}&cmpyId=${cmpyId}`;
    const result = await fetch(getBaseUrl() + url);
    const response = await result.json();
    return response;
  }

  static async removeTeamMember(id, accountId, cid,token) {
    const url = `shipper/team/remove-member?id=${id}&accountId=${accountId}&cid=${cid}`;

     return httpService(HttpMethods.DELETE, url, {}, {
       token,
       type: 'company_team_settings',
       method: 'delete',
       platform: 'Shipper'
     })
       .then((res) => {
         return res;
       })
       .catch((err) => console.log(err));
   
  }

  static async fetchForwarderPermission(id, name, token) {
    const url = `${getBaseUrl()}shipper/team/fetch-shipper-permission?id=${id}&name=${name}`;
    const result = await fetch(url, {
      headers: {
        Authorization: 'Bearer ' + token
      }
    });
    const response = await result.json();
    return response;
  }

  static async fetchShipperActivity(shipper_id, company_id, token, skip) {
    const url = `${getBaseUrl()}shipper/team/fetch-shipper-activity?shipper_id=${shipper_id}&company_id=${company_id}&skip=${skip}`;
    const result = await fetch(url, {
      headers: {
        Authorization: 'Bearer ' + token
      }
    });
    const response = await result.json();
    return response;
  }

  static async fetchTeamRoles(token) {
    const url = `${getBaseUrl()}shipper/fetch-team-roles`;
    const result = await fetch(url, {
      headers: {
        Authorization: 'Bearer ' + token
      }
    });
    const response = await result.json();
    return response;
  }

  static async update_role(token,data) {
    const url = 'shipper/team/update-role';

      return httpService(HttpMethods.PUT, url, data, {
        token,
        type: 'company_team_settings',
        method: 'edit',
        platform: 'Shipper'
      })
        .then((res) => {
          return res;
        })
        .catch((err) => console.log(err));
    
  }
}
