const AccountType = Object.freeze({
  SYSTEM: 'SYSTEM',
  APP_ADMIN: 'APP_ADMIN',
  FORWARDER: 'FORWARDER',
  SHIPPER: 'SHIPPER',
  BROKER: 'BROKER'
});

export const UserType = Object.freeze({
  SYSTEM: "SYS",
  SUPPORT: "SUP",
  COLLABORATOR: "COL",
  SHIPPER: "SHP",
  FORWARDER: "FWD",
  BROKER: "BRK",
  AMBASSADOR: "AMB"
});


export default AccountType;
