import { HttpMethods, HttpService } from './index';
import { getBaseUrl } from '../config/config';


export default class ShipperService {
  static createShipper(data) {
    const url = 'user/shipper-registration';
    return HttpService.request(HttpMethods.POST, url, data)
      .then((res) => {
        console.log('createUser SRVICE resp', res);
        return res;
      })
      .catch((err) => console.error(err));
  }

  static verifyShipperEmail(data) {
    const url = 'user/verify-shipper-registration';
    return HttpService.request(HttpMethods.POST, url, data)
      .then((res) => {
        console.log('createUser SRVICE resp', res);
        return res;
      })
      .catch((err) => console.error(err));
  }

  static async verifyShipperUsername(username) {
    const url = `${getBaseUrl()}user/verify-shipper-username?username=${username}`;
    const res = await fetch(url);
    const result = await res.json();
    return result;
  }

  static async verifyShipperReferralCode(referralCode) {
    const url = `${getBaseUrl()}user/verify-shipper-referral-code?referralCode=${referralCode}`;
    const res = await fetch(url);
    const result = await res.json();
    return result;
  }



  static generate_custom_shipping_lead(data) {
    const url = 'tool/generate-custom-shipping-lead';
    return HttpService.request(HttpMethods.POST, url, data)
      .then((res) => {
        console.log('createUser SRVICE resp', res);
        return res;
      })
      .catch((err) => console.error(err));
  }

  static fetch_custom_shipping_lead(id, page, data) {
    const url = `tool/fetch-shipping-lead-shipper?id=${id}&page=${page}`;
    return HttpService.request(HttpMethods.POST, url, data)
      .then((res) => {
        console.log('createUser SRVICE resp', res);
        return res;
      })
      .catch((err) => console.error(err));
  }

  static async fetch_proposal_for_single_shipping_lead(id, token) {
    const url = `${getBaseUrl()}shipper/get-all-proposal-for-shipment-requests?shipment_request=${id}`;
    const res = await fetch(url, {
      headers: {
        Authorization: 'Bearer ' + token
      }
    });
    const result = await res.json();
    return result;
    // return HttpService.request(HttpMethods.GET, url)
    //     .then((res) => {
    //         console.log('createUser SRVICE resp', res);
    //         return res;
    //     })
    //     .catch((err) => console.error(err));
  }

  static async changeCustomQuoteStatus(id, status, token) {
    const url = `${getBaseUrl()}shipper/change-custom-quote-status?id=${id}&status=${status}`;
    const res = await fetch(url, {
      headers: {
        Authorization: 'Bearer ' + token
      }
    });
    const result = await res.json();
    return result;
  }

  // static async fetch_details_for_entering_basic_info(id) {
  //   const baseURL = getBaseUrl();
  //   const url = `${baseURL}shipper/fetch-details-for-entering-basic-info?id=${id}`;
  //   const res = await fetch(url);
  //   const result = await res.json();
  //   return result;
  //   // return HttpService.request(HttpMethods.GET, url)
  //   //     .then((res) => {
  //   //         console.log('createUser SRVICE resp', res);
  //   //         return res;
  //   //     })
  //   //     .catch((err) => console.error(err));
  // }



  static async fetch_shipment(id, data, token) {
    // &status=${status}&shipment_id=${shipment_id}
    const url = `shippment/fetch-shipment-shipper?shipper=${id}`;
    const response = await fetch(`${getBaseUrl()}${url}`, {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + token,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    });
    const result = await response.json();
    return result;
  }

  static fetch_shipment_by_id(id) {
    const url = `shippment/fetch-shippment-by-id?id=${id}`;
    return HttpService.request(HttpMethods.GET, url)
      .then((res) => {
        console.log('createUser SRVICE resp', res);
        return res;
      })
      .catch((err) => console.error(err));
  }

  static fetch_shipment_details_by_id(id, type) {
    const url = `shippment/fetch-shipment-details-by-id?id=${id}&type=${type}`;
    return HttpService.request(HttpMethods.GET, url)
      .then((res) => {
        console.log('createUser SRVICE resp', res);
        return res;
      })
      .catch((err) => console.error(err));
  }

  static create_consignee(data) {
    const url = 'shippment/create-consignee';
    return HttpService.request(HttpMethods.POST, url, data)
      .then((res) => {
        console.log('createUser SRVICE resp', res);
        return res;
      })
      .catch((err) => console.error(err));
  }

  static fetch_consignee(id) {
    const url = `shippment/fetch-consignee?id=${id}`;
    return HttpService.request(HttpMethods.GET, url)
      .then((res) => {
        console.log('createUser SRVICE resp', res);
        return res;
      })
      .catch((err) => console.error(err));
  }

  static delete_consignee(id) {
    const url = `shippment/delete-consignee?id=${id}`;
    return HttpService.request(HttpMethods.DELETE, url)
      .then((res) => {
        console.log('createUser SRVICE resp', res);
        return res;
      })
      .catch((err) => console.error(err));
  }

  static update_consignee(id, data) {
    const url = `shippment/update-consignee?id=${id}`;
    return HttpService.request(HttpMethods.PUT, url, data)
      .then((res) => {
        console.log('createUser SRVICE resp', res);
        return res;
      })
      .catch((err) => console.error(err));
  }

  static create_consignor(data) {
    const url = 'shippment/create-consignor';
    return HttpService.request(HttpMethods.POST, url, data)
      .then((res) => {
        console.log('createUser SRVICE resp', res);
        return res;
      })
      .catch((err) => console.error(err));
  }

  static fetch_consignor(id) {
    const url = `shippment/fetch-consignor?id=${id}`;
    return HttpService.request(HttpMethods.GET, url)
      .then((res) => {
        console.log('createUser SRVICE resp', res);
        return res;
      })
      .catch((err) => console.error(err));
  }

  static update_consignor(id, data) {
    const url = `shippment/update-consignor?id=${id}`;
    return HttpService.request(HttpMethods.PUT, url, data)
      .then((res) => {
        console.log('createUser SRVICE resp', res);
        return res;
      })
      .catch((err) => console.error(err));
  }

  static delete_consignor(id) {
    const url = `shippment/delete-consignor?id=${id}`;
    return HttpService.request(HttpMethods.DELETE, url)
      .then((res) => {
        console.log('createUser SRVICE resp', res);
        return res;
      })
      .catch((err) => console.error(err));
  }

  static create_payment_intent(id) {
    const url = `payment/create-payment-intent?id=${id}`;
    return HttpService.request(HttpMethods.GET, url)
      .then((res) => {
        console.log('createUser SRVICE resp', res);
        return res;
      })
      .catch((err) => console.error(err));
  }

  static update_payment_info(id, transaction_id) {
    const url = `payment/update-payment-intent?id=${id}&transaction_id=${transaction_id}`;
    return HttpService.request(HttpMethods.GET, url)
      .then((res) => {
        console.log('createUser SRVICE resp', res);
        return res;
      })
      .catch((err) => console.error(err));
  }

  static async createNote(data) {
    const url = `${getBaseUrl()}shippment/create-shippment-note`;
    const res = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    });
    const result = await res.json();
    return result;
  }

  static async fetchNotes(id, type) {
    const url = `${getBaseUrl()}shippment/fetch-shippment-note?id=${id}&type=${type}`;
    const res = await fetch(url);
    const result = await res.json();
    return result;
  }

  static async fetch_shipment_documents(id, token) {
    const url = `${getBaseUrl()}shippment/fetch-shippment-documents?id=${id}`;
    const res = await fetch(url, {
      headers: {
        Authorization: 'Bearer ' + token
      }
    });
    const result = await res.json();
    return result;
  }

  static async shipperAnalyticsShipment(id, data,token) {
    const url = `${getBaseUrl()}shipper/shipper-analytics-shipment?id=${id}`;
    const res = await fetch(url, {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        Authorization: 'Bearer ' + token,
        'Content-Type': 'application/json'
      }
    });
    const result = await res.json();
    return result;
  }

  static async acceptMyShipperInvitaion(data) {
    const url = `${getBaseUrl()}shipper/accept-my-shipper-invitaion`;
    const res = await fetch(url, {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json'
      }
    });
    const result = await res.json();
    return result;
  }

  static async cancelShipment(data, token) {
    const url = `${getBaseUrl()}shippment/cancel-shipment`;
    const res = await fetch(url, {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + token,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    });
    const result = await res.json();
    return result;
  }

  static async fetchShipmentStatus(id) {
    const url = `${getBaseUrl()}shippment/fetch-shippment-status?id=${id}`;
    const res = await fetch(url);
    const result = await res.json();
    return result;
  }

  static async checkShipmentOwnerAndStepsCleared(data, token) {
    const url = `${getBaseUrl()}shippment/check-shipment-owner-and-steps-cleared`;
    const res = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      },
      body: JSON.stringify(data)
    });
    const result = await res.json();
    return result;
  }

  static async completShipment(data, token) {
    const url = `${getBaseUrl()}shippment/complete-shipment`;
    const res = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      },
      body: JSON.stringify(data)
    });
    const result = await res.json();
    return result;
  }

  static async saveRating(data, token) {
    const url = `${getBaseUrl()}shippment/rating/create`;
    const res = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      },
      body: JSON.stringify(data)
    });
    const result = await res.json();
    return result;
  }

  static async checkReviewGiven(id, token) {
    const url = `${getBaseUrl()}shippment/rating/check?shipment_id=${id}`;
    const res = await fetch(url, {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + token
      }
    });
    const result = await res.json();
    return result;
  }



  static async fetch_amount_info(id, token) {
    const url = `${getBaseUrl()}shippment/fetch-amount-info?shipment_id=${id}`;
    const res = await fetch(url, {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + token
      }
    });
    const result = await res.json();
    return result;
  }

  static async fetchDataForRequestChange(id, token) {
    const url = `${getBaseUrl()}shippment/request-change/fetch-data-for-request-change?shipment_id=${id}`;
    const res = await fetch(url, {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + token
      }
    });
    const result = await res.json();
    return result;
  }

  static async saveRequestedService(data, token) {
    const url = `${getBaseUrl()}shippment/request-change/save-request-change`;
    const res = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      },
      body: JSON.stringify(data)
    });
    const result = await res.json();
    return result;
  }

  static async fetchRequestCreated(id, token) {
    const url = `${getBaseUrl()}shippment/request-change/fetch-request-change?shipment_id=${id}`;
    const res = await fetch(url, {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + token
      }
    });
    const result = await res.json();
    return result;
  }

  static async cancelRequestCreated(data, token) {
    const url = `${getBaseUrl()}shippment/request-change/cancel-request-change`;
    const res = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      },
      body: JSON.stringify(data)
    });
    const result = await res.json();
    return result;
  }

  static async acceptRequestChange(data, token) {
    const url = `${getBaseUrl()}shippment/request-change/accept-request-change`;
    const res = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      },
      body: JSON.stringify(data)
    });
    const result = await res.json();
    return result;
  }

  static create_payment_intent_for_due_amount(id) {
    const url = `payment/create-payment-intent-for-due-amount?shipment_id=${id}`;
    return HttpService.request(HttpMethods.GET, url)
      .then((res) => {
        console.log('createUser SRVICE resp', res);
        return res;
      })
      .catch((err) => console.error(err));
  }

  static async fetch_due_amount_info(id, token) {
    const url = `${getBaseUrl()}shippment/fetch-due-amount-info?shipment_id=${id}`;
    const res = await fetch(url, {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + token
      }
    });
    const result = await res.json();
    return result;
  }

  static update_payment_info_for_due_amount(id, transaction_id) {
    const url = `payment/update-payment-intent-for-due-amount?id=${id}&transaction_id=${transaction_id}`;
    return HttpService.request(HttpMethods.GET, url)
      .then((res) => {
        console.log('createUser SRVICE resp', res);
        return res;
      })
      .catch((err) => console.error(err));
  }

  static async fetch_shipment_lead_shipper(id, token) {
    const url = `${getBaseUrl()}shippment/fetch-shippment-lead-shippper?id=${id}`;
    const res = await fetch(url, {
      headers: {
        Authorization: 'Bearer ' + token
      }
    });
    const result = await res.json();
    return result;
  }

  static async fetch_all_services(token) {
    const url = `${getBaseUrl()}shippment/fetch-all-services`;
    const res = await fetch(url, {
      headers: {
        Authorization: 'Bearer ' + token
      }
    });
    const result = await res.json();
    return result;
  }

  static async fetch_shipment_tracking(token) {
    const url = `${getBaseUrl()}shippment/fetch-tracking-data`;
    const res = await fetch(url, {
      headers: {
        Authorization: 'Bearer ' + token
      }
    });
    const result = await res.json();
    return result;
  }
}
