import { HttpMethods, HttpService } from './index';

export default class ForwarderService {

    static createForwarder(data) {
        const url = 'user/forwarder-registration';
        return HttpService.request(HttpMethods.POST, url, data)
            .then((res) => {
                console.log('createUser SRVICE resp', res);
                return res;
            })
            .catch((err) => console.error(err));
    }

    static verifyForwarderEmail(data) {
        const url = 'user/verify-forwarder-registration';
        return HttpService.request(HttpMethods.POST, url, data)
            .then((res) => {
                console.log('createUser SRVICE resp', res);
                return res;
            })
            .catch((err) => console.error(err));
    }

}
