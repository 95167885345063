/* eslint-disable */
import {
  ADD_TRAVELER_FAILURE,
  ADD_TRAVELER_SUCCESS,
  ADD_TRIP_FAILURE,
  ADD_TRIP_SUCCESS,
  ADD_UPDATE_COLLABORATOR_FAILURE,
  ADD_UPDATE_COLLABORATOR_SUCCESS,
  ADD_WCITIES_FAILURE,
  ADD_WCITIES_SUCCESS,
  AUTOPICK_COVER_TRIP_FAILURE,
  AUTOPICK_COVER_TRIP_SUCESS,
  DELETE_TRIP_FAILURE,
  DELETE_TRIP_SUCCESS,
  DUPLICATE_TRIP_FAILURE,
  DUPLICATE_TRIP_SUCESS,
  GDS_IMPORT_FAILURE,
  GDS_IMPORT_SUCCESSS,
  GET_TRIP_BRANDING_FAILURE,
  IMPORT_AFAR_FAILURE,
  IMPORT_AFAR_SUCCESS,
  LOGOUT_REQUEST,
  NOTE_DATE_CHANGE_FAILURE,
  NOTE_DATE_CHANGE_SUCCESS,
  NOTE_PDF_ORDER_CHANGE_FAILURE,
  NOTE_PDF_ORDER_CHANGE_SUCCESS,
  PUBLISH_TRIP_FAILURE,
  PUBLISH_TRIP_SUCCESS,
  RECEIVE_AUTH_FAILURE,
  RECEIVE_COLLABORATORS_FAILURE,
  RECEIVE_USER_FIND_FAILURE,
  REMOVE_COLLABORATOR_FAILURE,
  REMOVE_COLLABORATOR_SUCCESS,
  REMOVE_TRAVELER_FAILURE,
  REMOVE_TRAVELER_SUCCESS,
  REQUEST_ACTIVITY_LOGS_TRIP_FAILURE,
  RESEND_INVITE_COLLABORATOR_FAILURE,
  RESEND_INVITE_COLLABORATOR_SUCCESS,
  TOGGLE_BRAND_TRIP_FAILURE,
  TOGGLE_BRAND_TRIP_SUCCESS,
  TOGGLE_MAIN_BRAND_TRIP_FAILURE,
  TOGGLE_MAIN_BRAND_TRIP_SUCCESS,
  UPDATE_RESERVATION_FAILURE,
  UPDATE_RESERVATION_SUCCESS,
  UPDATE_TRIP_FAILURE,
  UPDATE_TRIP_SUCCESS,
  EDIT_PCAC_TRIPS_SUCCESS,
  EDIT_PCAC_TRIPS_FAILURE,
  VIEW_PCAC_TRIPS_FAILURE,
  SIGNUP_FAILURE,
  SIGNUP_SUCCESS,
  USER_UPDATE_REQUEST,
  USER_INFO_REQUEST,
  USER_INFO_SUCCESS,
  USER_UPDATE_SUCCESS,
  USER_UPDATE_FAILURE,
  USER_INFO_FAILURE,
  BILLING_INFO_REQUEST,
  BILLING_INFO_SUCCESS,
  BILLING_INFO_FAILURE,
  TOASTIFY
} from '../actions';

import { CLOSE_TOAST_MESSAGE, REQUEST_IN_PROGRESS } from '../actions/message';

import {
  ADD_RESERVATION_FAILURE,
  ADD_RESERVATION_SUCCESS,
  DELETE_RESERVATION_FAILURE,
  DELETE_RESERVATION_SUCCESS,
  DOCUMENT_PAGE_COPY_FAILURE,
  DOCUMENT_PAGE_COPY_SUCCESS,
  SUPPLIER_INTEGRATION_IMPORT_FAILURE,
  SUPPLIER_INTEGRATION_IMPORT_SUCCESS,
  SUPPLIER_INTEGRATION_TOKEN_FAILURE
} from '../actions/reservations';

import {
  IMPORT_PDF_FAILURE,
  IMPORT_PDF_SUCCESSFUL,
  SEARCH_LIBRARY_FAILURE,
  UPLOAD_FAILED,
  UPLOAD_SUCCESSFUL
} from '../actions/upload';
import {
  RECEIVE_INFO_FAILURE,
  RECEIVE_INFO_SUCCESS,
  REQUEST_INFO
} from '../actions/info';

import {
  RECEIVE_QUOTE_FAILURE,
  RECEIVE_QUOTE_SUCCESS,
  REQUEST_QUOTE
} from '../actions/quotes';

import {
  INBOX_COPIED_TRIP_FAILURE,
  INBOX_COPIED_TRIP_SUCCESS,
  INBOX_CREATED_TRIP_FAILURE,
  INBOX_CREATED_TRIP_SUCCESS
} from '../actions/inbox';
import {
  LOGGED_IN_ELSEWHERE,
  RECEIVE_AUTH_SUCCESS,
  REQUEST_AUTH
} from '../actions/auth';

export const initialState = {
  isLoading: false,
  message: '',
  isMsgOpen: false,
  variant: 'info'
};

export default function (state = initialState, action) {
  switch (action.type) {
    case ADD_RESERVATION_SUCCESS:
    case UPDATE_RESERVATION_SUCCESS:
    case DELETE_RESERVATION_SUCCESS:
    case ADD_TRIP_SUCCESS:
    case UPDATE_TRIP_SUCCESS:
    case ADD_UPDATE_COLLABORATOR_SUCCESS:
    case REMOVE_COLLABORATOR_SUCCESS:
    case ADD_TRAVELER_SUCCESS:
    case REMOVE_TRAVELER_SUCCESS:
    case RESEND_INVITE_COLLABORATOR_SUCCESS:
    case DELETE_TRIP_SUCCESS:
    case DUPLICATE_TRIP_SUCESS:
    case TOGGLE_BRAND_TRIP_SUCCESS:
    case TOGGLE_MAIN_BRAND_TRIP_SUCCESS:
    case UPLOAD_SUCCESSFUL:
    case AUTOPICK_COVER_TRIP_SUCESS:
    case PUBLISH_TRIP_SUCCESS:
    case IMPORT_PDF_SUCCESSFUL:
    case GDS_IMPORT_SUCCESSS:
    case IMPORT_AFAR_SUCCESS:
    case ADD_WCITIES_SUCCESS:
    case SUPPLIER_INTEGRATION_IMPORT_SUCCESS:
    case DOCUMENT_PAGE_COPY_SUCCESS:
    case NOTE_DATE_CHANGE_SUCCESS:
    case NOTE_PDF_ORDER_CHANGE_SUCCESS:
    case EDIT_PCAC_TRIPS_SUCCESS:
      return {
        ...state,
        message: action.payload.msg,
        isMsgOpen: true,
        variant: 'success',
        isLoading: false
      };

    case INBOX_CREATED_TRIP_SUCCESS:
    case INBOX_COPIED_TRIP_SUCCESS:
      return {
        ...state,
        message: action.msg,
        isMsgOpen: true,
        variant: 'success',
        isLoading: false
      };

    case ADD_RESERVATION_FAILURE:
    case UPDATE_RESERVATION_FAILURE:
    case DELETE_RESERVATION_FAILURE:
    case ADD_TRIP_FAILURE:
    case UPDATE_TRIP_FAILURE:
    case RECEIVE_COLLABORATORS_FAILURE:
    case RECEIVE_USER_FIND_FAILURE:
    case ADD_UPDATE_COLLABORATOR_FAILURE:
    case REMOVE_COLLABORATOR_FAILURE:
    case ADD_TRAVELER_FAILURE:
    case REMOVE_TRAVELER_FAILURE:
    case RESEND_INVITE_COLLABORATOR_FAILURE:
    case REQUEST_ACTIVITY_LOGS_TRIP_FAILURE:
    case DELETE_TRIP_FAILURE:
    case DUPLICATE_TRIP_FAILURE:
    case GET_TRIP_BRANDING_FAILURE:
    case TOGGLE_BRAND_TRIP_FAILURE:
    case TOGGLE_MAIN_BRAND_TRIP_FAILURE:
    case UPLOAD_FAILED:
    case AUTOPICK_COVER_TRIP_FAILURE:
    case PUBLISH_TRIP_FAILURE:
    case IMPORT_PDF_FAILURE:
    case GDS_IMPORT_FAILURE:
    case IMPORT_AFAR_FAILURE:
    case ADD_WCITIES_FAILURE:
    case SUPPLIER_INTEGRATION_TOKEN_FAILURE:
    case SUPPLIER_INTEGRATION_IMPORT_FAILURE:
    case DOCUMENT_PAGE_COPY_FAILURE:
    case NOTE_DATE_CHANGE_FAILURE:
    case NOTE_PDF_ORDER_CHANGE_FAILURE:
    case EDIT_PCAC_TRIPS_FAILURE:
    case VIEW_PCAC_TRIPS_FAILURE:
    case SIGNUP_FAILURE:
      return {
        ...state,
        message: action.payload.msg,
        isMsgOpen: true,
        variant: 'error',
        isLoading: false
      };

    case SEARCH_LIBRARY_FAILURE:
    case INBOX_CREATED_TRIP_FAILURE:
    case INBOX_COPIED_TRIP_FAILURE:
    case LOGGED_IN_ELSEWHERE:
      return {
        ...state,
        message: action.payload.msg,
        isMsgOpen: true,
        variant: 'warning',
        isLoading: false
      };

    case CLOSE_TOAST_MESSAGE:
    case SIGNUP_SUCCESS:
    case LOGOUT_REQUEST:
      return {
        ...state,
        message: initialState.message,
        isMsgOpen: initialState.isMsgOpen,
        isLoading: false
      };

    case REQUEST_AUTH:
    case REQUEST_INFO:
    case REQUEST_QUOTE:
    case USER_UPDATE_REQUEST:
    case USER_INFO_REQUEST:
    case BILLING_INFO_REQUEST:
      return { ...state, isLoading: true };

    case RECEIVE_AUTH_SUCCESS:
    case RECEIVE_INFO_SUCCESS:
    case USER_UPDATE_SUCCESS:
    // case USER_INFO_SUCCESS:
    case BILLING_INFO_SUCCESS:
      return {
        ...state,
        message: action.payload.msg,
        isMsgOpen: true,
        variant: 'success',
        isLoading: false
      };

    case RECEIVE_AUTH_FAILURE:
    case RECEIVE_INFO_FAILURE:
    case USER_UPDATE_FAILURE:
    case USER_INFO_FAILURE:
    case BILLING_INFO_FAILURE:
      return {
        ...state,
        message: action.payload.msg,
        isMsgOpen: true,
        variant: 'warning',
        isLoading: false
      };

    case TOASTIFY:
      return {
        ...state,
        message: action.payload.msg,
        isMsgOpen: true,
        variant: action.payload.error,
        isLoading: false
      };

    case REQUEST_IN_PROGRESS:
      return { ...state, isLoading: action.payload };

    default:
      return { ...state, isLoading: false };
  }
}
/* eslint-enable */
