import { getBaseUrl } from 'config/config';
import { HttpMethods, HttpService } from './index';
import { httpService } from './global';

export default class SecurityService {
  static async fetchCookiesData(id, token) {
    const url = `shipper/fetch-cookies-data/${id}`;

     return httpService(HttpMethods.GET, url, null, {
       token,
       type: 'security_cookies_settings',
       method: 'read',
       platform: 'Shipper'
     })
       .then((res) => {
         return res;
       })
       .catch((err) => console.log(err));
   
  }

  static async fetchPrivacyData(id, token) {
    const url = `shipper/fetch-privacy-data/${id}`;

         return httpService(HttpMethods.GET, url, null, {
           token,
           type: 'security_privacy_settings',
           method: 'read',
           platform: 'Shipper'
         })
           .then((res) => {
             return res;
           })
           .catch((err) => console.log(err));
   
  }

  static async manageCookies(data, token) {
    const url = `shipper/update-cookies`;

         return httpService(HttpMethods.PUT, url, data, {
           token,
           type: 'security_cookies_settings',
           method: 'edit',
           platform: 'Shipper'
         })
           .then((res) => {
             return res;
           })
           .catch((err) => console.log(err));
  
  }

  static async managePrivacy(data, token) {
    const url = `shipper/update-privacy`;

          return httpService(HttpMethods.PUT, url, data, {
            token,
            type: 'security_privacy_settings',
            method: 'edit',
            platform: 'Shipper'
          })
            .then((res) => {
              return res;
            })
            .catch((err) => console.log(err));
   
  }

  static async deactivateAccount(data, token) {
    const url = `shipper/deactivate-account`;

          return httpService(HttpMethods.POST, url, data, {
            token,
            type: 'security_account_settings',
            method: 'delete',
            platform: 'Shipper'
          })
            .then((res) => {
              return res;
            })
            .catch((err) => console.log(err));
   
  }

  static async checkDeactivateAccountShipments(id, token) {
    const url = `shipper/check-deactivate-account-shipmets/${id}`;

     return httpService(HttpMethods.GET, url, null, {
       token,
       type: 'security_account_settings',
       method: 'delete',
       platform: 'Shipper'
     })
       .then((res) => {
         return res;
       })
       .catch((err) => console.log(err));
   
  
  }

  static async fetchLoginHistory(id, token) {
    const url = `shipper/fetch-login-history?id=${id}`;

      return httpService(HttpMethods.GET, url, null, {
        token,
        type: 'security_account_settings',
        method: 'read',
        platform: 'Shipper'
      })
        .then((res) => {
          return res;
        })
        .catch((err) => console.log(err));
  
  }
}
