import { getBaseUrl } from 'config/config';
import { HttpMethods, HttpService } from './index';
import { httpService } from './global';

export default class TransactionService {
  static async saveTransaction(data, token,type,method) {
    let url = `${getBaseUrl()}transaction/create`;

    const result = await fetch(url, {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
        type: type,
        method: method,
        platform: 'Shipper'
      }
    });
    const response = await result.json();
    return response;
  }
  static async fetchTransaction(shipper_id, token, page) {
    const url = `transaction/shipper/fetch?shipper_id=${shipper_id}&page=${page}`;

      return httpService(HttpMethods.GET, url, null, {
        token,
        type: 'billing',
        method: 'read',
        platform: 'Shipper'
      })
        .then((res) => {
          return res;
        })
        .catch((err) => console.log(err));
  
  }

  static async fetchTransactionAnalytics(shipper_id, token) {
    const url = `transaction/shipper/fetch-analytics?shipper_id=${shipper_id}`;

    return httpService(HttpMethods.GET, url, null, {
      token,
      type: 'billing',
      method: 'read',
      platform: 'Shipper'
    })
      .then((res) => {
        return res;
      })
      .catch((err) => console.log(err));
  
  }

  static async fetchTransactionAmountAnalytics(shipper_id, token) {
    const url = `transaction/shipper/fetch-transaction-analytics?shipper_id=${shipper_id}`;

    return httpService(HttpMethods.GET, url, null, {
      token,
      type: 'billing',
      method: 'read',
      platform: 'Shipper'
    })
      .then((res) => {
        return res;
      })
      .catch((err) => console.log(err));
  
  }


}
