import { httpService, HttpMethods } from "./global";


export default class ShipmentsService {
  static save_quote_search_data(data, token) {
    const url = 'shipment/s/save-search-data';
    return httpService(HttpMethods.POST, url, data, token)
      .then((res) => {
        return res;
      })
      .catch((err) => console.log(err));
  }

  static fetch_quote_search_data(id, token) {
    const url = `shipment/s/fetch-search-data?id=${id}`;
    return httpService(HttpMethods.GET, url, {}, token)
      .then((res) => {
        return res;
      })
      .catch((err) => console.log(err));
  }

  static fetch_rate_sheet_by_search_for_shipper(id, token) {
    const url = `shipment/s/fetch-rate-sheet-by-search-for-shipper?id=${id}`;
    return httpService(HttpMethods.GET, url, {}, token)
      .then((res) => {
        return res;
      })
      .catch((err) => console.log(err));
  }

  static create_shipment(data, token) {
    const url = `shipment/s/create`;
    return httpService(HttpMethods.POST, url, data, {
      token,
      type: 'shipments',
      method: "create",
      platform: "Shipper"
    })
      .then((res) => {
        return res;
      })
      .catch((err) => console.log(err));
  }

  static upload_shipment_documents(data, token) {
    const url = `shipment/s/upload-documents`;
    return httpService(HttpMethods.POST, url, data, {
      token,
      type: 'shipments',
      method: "create",
      platform: "Shipper"
    })
      .then((res) => {
        return res;
      })
      .catch((err) => console.log(err));
  }

  static fetch_shipment(data, token) {
    const url = `shipment/s/fetch`;
    return httpService(HttpMethods.POST, url, data, {
      token,
      type: 'shipments',
      method: "read",
      platform: "Shipper"
    })
      .then((res) => {
        return res;
      })
      .catch((err) => console.log(err));
  }

  static fetch_amount_info(data, token) {
    const url = `shipment/s/fetch-amount-info`;
    return httpService(HttpMethods.POST, url, data, token)
      .then((res) => {
        return res;
      })
      .catch((err) => console.log(err));
  }

  static fetch_addon_amount_info(data, token) {
    const url = `shipment/s/fetch-addon-amount-info`;
    return httpService(HttpMethods.POST, url, data, token)
      .then((res) => {
        return res;
      })
      .catch((err) => console.log(err));
  }














  static create_payemnt_intent(data, token) {
    const url = `shipment/s/create-payment-intent`;
    return httpService(HttpMethods.POST, url, data, {
      token,
      type: 'shipment_details',
      method: "pay_now",
      platform: "Shipper"
    })
      .then((res) => {
        return res;
      })
      .catch((err) => console.log(err));
  }
  static create__default_method_payemnt_intent(data, token) {
    const url = `shipment/s/create-default-method-payment-intent`;
    return httpService(HttpMethods.POST, url, data, {
      token,
      type: 'shipment_details',
      method: "pay_now",
      platform: "Shipper"
    })
      .then((res) => {
        return res;
      })
      .catch((err) => console.log(err));
  }
  static check_method_payemnt_intent_status(data, token, type, method) {
    const url = `shipment/s/check-payment-intent-status`;
    return httpService(HttpMethods.POST, url, data, {
      token,
      type: type,
      method: method,
      platform: "Shipper"
    })
      .then((res) => {
        return res;
      })
      .catch((err) => console.log(err));
  }

  static create_transaction_for_another_method(data, token) {
    const url = `shipment/s/create-transaction`;
    return httpService(HttpMethods.POST, url, data, {
      token,
      type: 'shipment_details',
      method: "pay_now",
      platform: "Shipper"
    })
      .then((res) => {
        return res;
      })
      .catch((err) => console.log(err));
  }

















  static update_payment_info(data, token) {
    const url = `shipment/s/update-payment-info`;
    return httpService(HttpMethods.POST, url, data,  {
      token,
      type: 'shipment_details',
      method: "pay_now",
      platform: "Shipper"
    })
      .then((res) => {
        return res;
      })
      .catch((err) => console.log(err));
  }

  static update_bank_payment_info(data, token) {
    const url = `shipment/s/update-bank-payment-info`;
    return httpService(HttpMethods.POST, url, data,  {
      token,
      type: 'shipment_details',
      method: "pay_now",
      platform: "Shipper"
    })
      .then((res) => {
        return res;
      })
      .catch((err) => console.log(err));
  }

  static update_shipment_addon_payment_info(data, token) {
    const url = `shipment/s/update-shipment-addon-payment-info`;
    return httpService(HttpMethods.POST, url, data, token)
      .then((res) => {
        return res;
      })
      .catch((err) => console.log(err));
  }

  static update_shipment_bank_addon_payment_info(data, token) {
    const url = `shipment/s/update-shipment-bank-addon-payment-info`;
    return httpService(HttpMethods.POST, url, data, token)
      .then((res) => {
        return res;
      })
      .catch((err) => console.log(err));
  }

  static fetchShipmentOverviewDetail(data, token) {
    const url = `shipment/s/shipment-page-overview`;
    return httpService(HttpMethods.POST, url, data, token)
      .then((res) => {
        return res;
      })
      .catch((err) => console.log(err));
  }

  static fetchShipmentContainerDetail(data, token) {
    const url = `shipment/s/container-detail`;
    return httpService(HttpMethods.POST, url, data, token)
      .then((res) => {
        return res;
      })
      .catch((err) => console.log(err));
  }

  static fetchShipmentActivity(data, token) {
    const url = `shipment/s/activity`;
    return httpService(HttpMethods.POST, url, data, token)
      .then((res) => {
        return res;
      })
      .catch((err) => console.log(err));
  }

  static fetchShipmentNotes(data, token) {
    const url = `shipment/s/notes/fetch`;
    return httpService(HttpMethods.POST, url, data, token)
      .then((res) => {
        return res;
      })
      .catch((err) => console.log(err));
  }

  static createShipmentNotes(data, token) {
    const url = `shipment/s/notes/create`;
    return httpService(HttpMethods.POST, url, data,  {
      token,
      type: 'shipment_details',
      method: "notes",
      platform: "Shipper"
    })
      .then((res) => {
        return res;
      })
      .catch((err) => console.log(err));
  }

  static fetchShipmentDocuments(data, token) {
    const url = `shipment/s/documents/fetch`;
    return httpService(HttpMethods.POST, url, data, token)
      .then((res) => {
        return res;
      })
      .catch((err) => console.log(err));
  }

  static fetchShipmentPayments(data, token) {
    const url = `shipment/s/payment/fetch`;
    return httpService(HttpMethods.POST, url, data, token)
      .then((res) => {
        return res;
      })
      .catch((err) => console.log(err));
  }


  static fetchInreviewPayments(shipment_id, token) {
    const url = `shipment/s/fetch-inreview-transaction?shipment_id=${shipment_id}`;
    return httpService(HttpMethods.GET, url, null, {
      token,
      type: 'shipment_details',
      method: "payment",
      platform: "Shipper"
    })
      .then((res) => {
        return res;
      })
      .catch((err) => console.log(err));
  }




  static trackContainerByContainerId(data) {
    const url = `shipment/container/track`;
    return httpService(HttpMethods.POST, url, data, null)
      .then((res) => {
        return res;
      })
      .catch((err) => console.log(err));
  }

  static shipmentBasicInfo(data) {
    const url = `shipment/shipment-basic-info`;
    return httpService(HttpMethods.POST, url, data, null)
      .then((res) => {
        return res;
      })
      .catch((err) => console.log(err));
  }

  static cancelShipment(data, token) {
    const url = `shipment/s/cancel`;
    return httpService(HttpMethods.POST, url, data,  {
      token,
      type: 'shipments',
      method: "delete",
      platform: "Shipper"
    })
      .then((res) => {
        return res;
      })
      .catch((err) => console.log(err));
  }

  static startChatting(shipment_id) {
    const url = `shipment/chat?shipment_id=${shipment_id}`;
    return httpService(HttpMethods.GET, url, null, null)
      .then((res) => {
        return res;
      })
      .catch((err) => console.log(err));
  }

  static fetchDataForRequestAddon(data, token) {
    const url = `shipment/s/add-on/fetch-services`;
    return httpService(HttpMethods.POST, url, data, token)
      .then((res) => {
        return res;
      })
      .catch((err) => console.log(err));
  }

  static createRequestAddon(data, token) {
    const url = `shipment/s/add-on/create`;
    return httpService(HttpMethods.POST, url, data,  {
      token,
      type: 'shipment_details',
      method: "add_on",
      platform: "Shipper"
    })
      .then((res) => {
        return res;
      })
      .catch((err) => console.log(err));
  }

  static fetchRequestAddon(data, token) {
    const url = `shipment/s/add-on/fetch`;
    return httpService(HttpMethods.POST, url, data, token)
      .then((res) => {
        return res;
      })
      .catch((err) => console.log(err));
  }

  static withdrawAddon(data, token) {
    const url = `shipment/s/add-on/withdraw`;
    return httpService(HttpMethods.POST, url, data,  {
      token,
      type: 'shipment_details',
      method: "add_on",
      platform: "Shipper"
    })
      .then((res) => {
        return res;
      })
      .catch((err) => console.log(err));
  }

  static fetchDelivery(data, token) {
    const url = `shipment/s/delivery/fetch`;
    return httpService(HttpMethods.POST, url, data,  {
      token,
      type: 'shipment_details',
      method: "delivery",
      platform: "Shipper"
    })
      .then((res) => {
        return res;
      })
      .catch((err) => console.log(err));
  }

  static acceptDelivery(data, token) {
    const url = `shipment/s/delivery/accept`;
    return httpService(HttpMethods.POST, url, data, {
      token,
      type: 'shipment_details',
      method: "delivery",
      platform: "Shipper"
    })
      .then((res) => {
        return res;
      })
      .catch((err) => console.log(err));
  }

  static rejectDelivery(data, token) {
    const url = `shipment/s/delivery/reject`;
    return httpService(HttpMethods.POST, url, data, {
      token,
      type: 'shipment_details',
      method: "delivery",
      platform: "Shipper"
    })
      .then((res) => {
        return res;
      })
      .catch((err) => console.log(err));
  }

  static uploadAdditionalAttachments(data, token) {
    const url = `shipment/s/upload/additional-attachments`;
    return httpService(HttpMethods.POST, url, data, {
      token,
      type: 'shipments',
      method: "edit",
      platform: "Shipper"
    })
      .then((res) => {
        return res;
      })
      .catch((err) => console.log(err));
  }

  static contactSupoort(data) {
    const url = `shipment/contact/support`;
    return httpService(HttpMethods.POST, url, data, null)
      .then((res) => {
        return res;
      })
      .catch((err) => console.log(err));
  }

  static getTrackingStatusFromVizion(data) {
    const url = `shipment/get-conatiner-status-using-referenceId/${data?.shipmentId}/${data?.containerId}`;
    return httpService(HttpMethods.GET, url, null, null)
      .then((res) => {
        return res;
      })
      .catch((err) => console.log(err));
  }

  static fetchOverviewPageAttachments(data, token) {
    const url = `shipment/s/overview/attachments/fetch`;
    return httpService(HttpMethods.POST, url, data, token)
      .then((res) => {
        return res;
      })
      .catch((err) => console.log(err));
  }




  static checkPaymentStatus(token, type, id) {
    const url = `shipment/s/check-payment-status?type=${type}&id=${id}`;
    return httpService(HttpMethods.GET, url, null, token)
      .then((res) => {
        return res;
      })
      .catch((err) => console.log(err));
  }

  static fetchContainersLocation(data) {

    const url = `shipment/getContainerLocations`;
    return httpService(HttpMethods.POST, url, data, null)
      .then((res) => {
        return res;
      })
      .catch((err) => console.log(err));
  }

  static fetchAllContainersOriginAndDestination(token) {

    const url = `shipment/get-all-containers-origin-and-destination-locations`;
    return httpService(HttpMethods.GET, url, null, {
      token,
      type: 'dashboard',
      method: 'read',
      platform: 'Shipper'
    })
      .then((res) => {
        return res;
      })
      .catch((err) => console.log(err));
  }

}
