export const SHIPMENT_SEARCH_INFO = 'SHIPMENT_SEARCH_INFO';
export const shippmentSearchInfo = (information) => {
  return {
    type: SHIPMENT_SEARCH_INFO,
    payload: information,
  };
};



// export const SHIPPMENT_BOOKING_DOCUMENTS = 'SHIPPMENT_BOOKING_DOCUMENTS';
// export const shippmentBookingDocuments = (information) => {
//   return {
//     type: SHIPPMENT_BOOKING_DOCUMENTS,
//     payload: information,
//   };
// };



// export const SHIPPMENT_PAYMENT_INFO = 'SHIPPMENT_PAYMENT_INFO';
// export const shippmentPaymentInfo = (information) => {
//   return {
//     type: SHIPPMENT_PAYMENT_INFO,
//     payload: information,
//   };
// };