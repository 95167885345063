import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { checkSession } from '../../../../actions';

import ShipperRoute from './ShipperRoute';

const mapStateToProps = (state) => ({
  auth: state.session.auth
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      checkSession
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ShipperRoute);
