import { getBaseUrl } from 'config/config';
import { HttpMethods, HttpService } from './index';
import { httpService } from './global';

export default class BillingService {
  static async fetchBiilingChartData(id, token,year) {
    const url = `shipper/fetch-billing-chart/${id}?year=${year}`;

     return httpService(HttpMethods.GET, url, null, {
       token,
       type: 'billing',
       method: 'read',
       platform: 'Shipper'
     })
       .then((res) => {
         return res;
       })
       .catch((err) => console.log(err));
   
  }
}
