import { getBaseUrl } from 'config/config';
import { HttpMethods, HttpService } from './index';
import { httpService } from './global';

export default class ReferralService {
  static async fetchReferrals(id, token, page) {
    const url = `shipper/fetch-referrals/${id}?page=${page}`;
     return httpService(HttpMethods.GET, url, null, {
       token,
       type: 'refferal_settings',
       method: 'read',
       platform: 'Shipper'
     })
       .then((res) => {
         return res;
       })
       .catch((err) => console.log(err));
   
  }

  static async fetchReferralsAnalytics(id, token) {
    const url = `shipper/fetch-referrals-analytics/${id}`;

     return httpService(HttpMethods.GET, url, null, {
       token,
       type: 'refferal_settings',
       method: 'read',
       platform: 'Shipper'
     })
       .then((res) => {
         return res;
       })
       .catch((err) => console.log(err));
   
  }

  static async fetchReferralsAmount(token) {
    const url = `shipper/fetch-referrals-amount`;

     return httpService(HttpMethods.GET, url, null, {
       token,
       type: 'refferal_settings',
       method: 'read',
       platform: 'Shipper'
     })
       .then((res) => {
         return res;
       })
       .catch((err) => console.log(err));
    
  }
}
