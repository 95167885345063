import { httpService, HttpMethods } from "./global";

export default class DocumentService {
  // static searchDocFromLibrary(data, token) {
  //   return HttpService.request(HttpMethods.POST, 'api/wi/v1/guide/destinationSearch', data, {}, token).then(res => res);
  // }

  // static attachDocToTrip(data, token) {
  //   return HttpService.request(HttpMethods.POST, 'api/wi/v1/trips/newTrip/addGuide', data, {}, token).then(res => res);
  // }
  static fetchDocumentsShipper(data, token) {
    const url = `documents/s/fetch`;

     return httpService(HttpMethods.POST, url, data, {
      token,
      type: 'documents',
      method: 'read',
      platform: 'Shipper'
    })
      .then((res) => {
        return res;
      })
      .catch((err) => console.log(err));
  
  }

 


  // static async fetchDocumentsShipper(data, token) {
  //   const url = `${getBaseUrl()}shippment/documents/fetch-shipper`;
  //   const res = await fetch(url, {
  //     method: 'POST',
  //     headers: {
  //       Authorization: 'Bearer ' + token,
  //       'Content-Type': 'application/json',
  //     },
  //     body: JSON.stringify(data)
  //   });
  //   const result = await res.json();
  //   return result;
  // }




}
