import { HttpMethods, HttpService } from './index';
import { httpService } from './global';

export default class PaymentService {
  static fetchStripePublicKey() {
    let url = 'stripe/publicKey';

    return HttpService.request(HttpMethods.GET, url, {})
      .then((res) => {
        console.log('fetching Stripe key resp', res);
        return res;
      })
      .catch((err) => console.error(err));
  }


  static webhook() {
    let url = 'stripe/webhook';

    return HttpService.request(HttpMethods.POST, url, {})
      .then((res) => {
        console.log('webhook resp', res);
        return res;
      })
      .catch((err) => console.error(err));
  }

  static fetchSavedCards(uid, token) {
    return HttpService.request(
      HttpMethods.GET,
      `stripe/${uid}/fetchCards `,
      {},
      {},
      token
    )
      .then((res) => res)
      .catch((err) => console.error(err));
  }






  // static setupIntent(uid) {
  //   let url = `/s/payment-method/create-setup-intent`;

  //   return HttpService.request(HttpMethods.POST, url, {})
  //     .then((res) => {
  //       console.log('setupIntent resp', res);
  //       return res;
  //     })
  //     .catch((err) => console.error(err));
  // }



  static async setupIntent(token) {
    let url = `payment/s/payment-method/create-setup-intent`;
    return httpService(HttpMethods.GET, url, null, {
      token,
      type: 'billing',
      method: 'create',
      platform: 'Shipper'
    })
      .then((res) => {
        return res;
      })
      .catch((err) => console.log(err));
  }



  static async fetchPaymentMethods(token) {
    let url = `payment/s/payment-method/fetch`;
    return httpService(HttpMethods.GET, url, null, {
      token,
      type: 'billing',
      method: 'create',
      platform: 'Shipper'
    })
      .then((res) => {
        return res;
      })
      .catch((err) => console.log(err));
  }



  static async savePaymentMethod(data, token) {
    let url = `payment/s/payment-method/save`;
    return httpService(HttpMethods.POST, url, data, {
      token,
      type: 'billing',
      method: 'create',
      platform: 'Shipper'
    })
      .then((res) => {
        return res;
      })
      .catch((err) => console.log(err));
  }

  static async makeDefaultPaymentMethod(data, token) {
    let url = `payment/s/payment-method/make-default`;
    return httpService(HttpMethods.POST, url, data, {
      token,
      type: 'billing',
      method: 'create',
      platform: 'Shipper'
    })
      .then((res) => {
        return res;
      })
      .catch((err) => console.log(err));
  }

  static async removePaymentMethod(data, token) {
    let url = `payment/s/payment-method/remove`;
    return httpService(HttpMethods.POST, url, data, {
      token,
      type: 'billing',
      method: 'create',
      platform: 'Shipper'
    })
      .then((res) => {
        return res;
      })
      .catch((err) => console.log(err));
  }













}
