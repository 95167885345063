import {
    SHIPMENT_SEARCH_INFO,
    shippmentSearchInfo
} from '../actions';

const initialState = {
    search_data: {},
    rate_sheet: {},
    amount: {},
    // forwarder_id:"",
    origin_city_id: "",
    carrier_id: "",
    expiry_date: "",
    service_charges: [],
    isCustomLead: false,
    customLeadId: "",
    commodity: [],
    proposal_id: ""
};

export default function shippmentBooking(state = initialState, action) {
    switch (action.type) {
        case SHIPMENT_SEARCH_INFO:
            return {
                search_data: action.payload.search_data,
                rate_sheet: action.payload.rate_sheet,
                amount: action.payload.amount,
                // forwarder_id:action.payload.forwarder_id,
                expiry_date: action.payload.expiry_date,
                origin_city_id: action.payload.origin_city_id,
                carrier_id: action.payload.carrier_id,
                service_charges: action.payload.service_charges,
                isCustomLead: action.payload.isCustomLead,
                customLeadId: action.payload.customLeadId,
                proposal_id: action.payload.proposal_id,
                commodity: action.payload.commodity,

            };
        default:
            return state;
    }
}