import { ForwarderService, ProfileService, ShipperService } from '../service';

export const SIGNUP_REQUEST = 'SIGNUP_REQUEST';
export const requestSignup = (payload) => ({
  type: SIGNUP_REQUEST,
  payload
});

export const SIGNUP_SUCCESS = 'SIGNUP_SUCCESS';
export const receiveSignup = (payload) => ({
  type: SIGNUP_SUCCESS,
  payload
});

export const SIGNUP_FAILURE = 'SIGNUP_FAILURE';
export const signupError = (payload) => ({
  type: SIGNUP_FAILURE,
  payload
});

export function registerUser(userType, userData) {
  return (dispatch) => {
    console.log('dispatch req: ', userType, userData);
    dispatch(requestSignup(userData));

    return ProfileService.createUser(userType, userData).then((res) => {
      console.log('req rez: ', res);
      sendSignupActions(dispatch, res);
    });
  };
}

function sendSignupActions(dispatch, res) {
  if (res && res.returnCode === 0) {
    return dispatch(receiveSignup(true));
  } else {
    return dispatch(
      signupError({ msg: 'Error - could not register user. Please try again.' })
    );
  }
}

export const EXISITING_EMAIL_REQUEST = 'EXISITING_EMAIL_REQUEST';
export const requestExisitingEmail = (payload) => ({
  type: EXISITING_EMAIL_REQUEST,
  payload
});

export const EXISITING_EMAIL_SUCCESS = 'EXISITING_EMAIL_SUCCESS';
export const existingEmailSuccess = (payload) => ({
  type: EXISITING_EMAIL_SUCCESS,
  payload
});

export const EXISITING_EMAIL_ERROR = 'EXISITING_EMAIL_ERROR';
export const existingEmailError = (payload) => ({
  type: EXISITING_EMAIL_ERROR,
  payload
});

function sendExistingEmailActions(dispatch, res) {
  if (res) {
    return dispatch(existingEmailSuccess(res));
  } else {
    return dispatch(existingEmailError(res));
  }
}

export function isExistingUser(data) {
  return (dispatch) => {
    console.log('dispatch req: ', data);
    dispatch(requestExisitingEmail(data));

    return ProfileService.isExistingUser(data).then((res) => {
      console.log('req rez: ', res);
      sendExistingEmailActions(dispatch, res);
      return res;
    });
  };
}

export function validateReferralCode(data) {
  return (dispatch) => {
    console.log('dispatch req: ', data);
    // dispatch(requestExisitingEmail(data));

    return ProfileService.validateReferralCode(data).then((res) => {
      console.log('req rez: ', res);
      // sendExistingEmailActions(dispatch, res);
      return res;
    });
  };
}



// SHIPPER REGISTRATION BY RAJKUMAR JAISWAL (12/07/2023)

export function ShipperRegistration(data) {
  return (dispatch) => {
    return ShipperService.createShipper(data).then((res) => {
      // console.log('req rez: ', res);
      // sendExistingEmailActions(dispatch, res);
      return res;
    });
  }
}

export function ShipperRegistrationVerification(data) {
    return ShipperService.verifyShipperEmail(data).then((res) => {
      return res;
    });
}


// export function ShipperUsernameVerification(data) {
//   return ShipperService.verifyShipperUsername(data).then((res) => {
//     return res;
//   });
// }

// export function ShipperReferralCodeVerification(data) {
//   return ShipperService.verifyShipperReferralCode(data).then((res) => {
//     return res;
//   });
// }







// FORWARDER REGISTRATION BY RAJKUMAR JAISWAL (13/07/2023)

export function ForwarderRegistration(data) {
  return (dispatch) => {
    return ForwarderService.createForwarder(data).then((res) => {
      // console.log('req rez: ', res);
      // sendExistingEmailActions(dispatch, res);
      return res;
    });
  }
}

export function ForwarderRegistrationVerification(data) {
    return ForwarderService.verifyForwarderEmail(data).then((res) => {
      return res;
    });
}