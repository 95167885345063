import { HttpMethods, HttpService } from './index';
import { getBaseUrl } from '../config/config';


export default class ChatService {
 
    static createChat(data) {
        const url = 'chat/create';
        return HttpService.request(HttpMethods.POST, url, data)
            .then((res) => {
                console.log('createUser SRVICE resp', res);
                return res;
            })
            .catch((err) => console.error(err));
    }

    // static updateChat(data) {
    //     const url = 'chat/update';
    //     return HttpService.request(HttpMethods.PUT, url, data)
    //         .then((res) => {
    //             console.log('createUser SRVICE resp', res);
    //             return res;
    //         })
    //         .catch((err) => console.error(err));
    // }

    static fetchChatShipper(id) {
        const url = `chat/shipper-fetch?shipper_id=${id}`;
        return HttpService.request(HttpMethods.GET, url)
            .then((res) => {
                console.log('createUser SRVICE resp', res);
                return res;
            })
            .catch((err) => console.error(err));
    }


    static async getChatRoomIdFromShipmentId(id,token) {
        const url = `${getBaseUrl()}chat/get-chatRoomId-from-shipmentId?shipment_id=${id}`;
        const result = await fetch(url, {
            headers: {
                "Authorization": "Bearer "+token
            }
        }) 
        const response = await result.json()
        return response
    }

}
