import { getBaseUrl } from 'config/config';
import { HttpMethods, HttpService } from './index';

export default class DashboardService {
  static async fetchShipmentReport(id, token,year) {
    const url = `${getBaseUrl()}shipper/fetch-shipment-report/${id}?year=${year}`;
    const result = await fetch(url, {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + token,
        platform: "Shipper",
        method: "shipment_report",
        type:"dashboard"
      }
    });
    const response = await result.json();
    return response;
  }
}
